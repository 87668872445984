/* .datepicker-date-display{
    background-color: #6A1B9A !important;
}

.btn-flat, .is-today{
    color: #AB47BC !important;
} */

/* input:focus {
    border-bottom:1px solid #6A1B9A !important;
    box-shadow: 0 1px 0 0 #6A1B9A !important;
    -webkit-box-shadow:0 1px 0 0 #6A1B9A !important;
} */

/* .addTrip, .month-prev, .month-next {
    color: #AB47BC !important;
} */
/* .is-selected {
    background-color: #AB47BC !important;
} */
body {
    overflow:hidden;
}

.addTrip {
    font-size: 1.5rem;
    min-width: 40px !important;
    
}
.get-started-text{
    margin: auto 5px;
    color: #424242;
    font-size: calc(15px + 1vmin);
    text-align: center;
}

/* new trip modal resize issue fix */
#newtrip {
    width: 90%;
    max-height: 90%;
    -webkit-overflow-scrolling: touch;
}

.tooltip {
    /* visibility: visible; */
    width: 120px;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    top: 73px;
    left: 55px;
    
}

.site-logo {
    position: absolute;
    left: 3%;
    top: 1%;
    vertical-align: middle;
}
.tooltip-hidden {
    display: none;
}
.tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #424242 transparent transparent;
}
.searchbar {
    margin: 0 10px 10px 10px;
    width: 70%;
}

.addtrip-searchtrip {
    display: flex;
    margin-left: 0 !important;
    margin-bottom: 5px !important;

}

.trip-list.section {
    border-right: 3px solid #b2dfdb;
    height: 60vh;
    overflow-y: auto;
    padding-top: 0;
    padding-bottom: 50px;
}


.fa-map-pin:hover {
    transform: scale(1.4);
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 190, .75);
    border-radius: 50%;
    background-color: rgba(255, 255, 190, .4);;
}
.fa-map-pin-hover {
    transform: scale(1.4);
    box-shadow: 0px 0px 15px 5px rgba(255, 255, 190, .75);
    border-radius: 50%;
    background-color: rgba(255, 255, 190, .4);;
}

.trip-list-item {
    margin: 10px;
    margin-right: 20px;
    font-size: 14px;
    /* color: #616161  !important; */
}
.modal-trigger {
    color: #616161  !important;
}

.trip-list-item:hover {
    background-color: #b2dfdb;
    /* margin-right: 20px; */
    color: #004d40 !important;
    
}
.trip-list-item-hover{
    background-color: #b2dfdb;
    color: #004d40 !important;
}

.dashboard.section {
    margin-right: 11.25px;
    /* margin-bottom: 11.25px; */
}

#mapbox {
    display: flex;
    height: 88vh;
    overflow:hidden;
}

.mapbox-gl {
    height: 100%;
    border-radius: 2px;
}

#myform.white {
    padding: 0 !important;
    background-color: rgba(255, 255, 255, 0) !important;
    margin: 0;
    min-width: 100%;
}
.add-new-trip-container {
    width: 80% !important;
}

.datepicker-modal {
    width: 80% !important;
}

.modal-footer{
    padding-bottom: 0;
}

.liquorcabinet {
    width : 100%;
    position: absolute;
    top :50px;
    /* display: grid; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

/* picture frame */
.gallery-wall{
    float: right;
    right: -6vw;
    /* position: relative; */
}
.picture-frame {
    font-size: 1.7em;
    font-weight: 200;
    width:56px; 
    height:56px;
    float: left;
    /* margin-top: 10px; */
    /* margin-right: 10px; */
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius: 50px;
    background-color: teal;
    border: 3px solid teal;
}
.picture-frame:hover {
    /* background-color:  #4db6ac; */
    /* border: 2px solid #4db6ac; */
    /* transform: scale(1.01); */
}

.picture-frame img {
    width: 101%;
    height: 101%;
    object-fit: cover;
    border-radius: 50%;
}

.foward-action-shown{
    position: absolute;
    top: 60px;
    /* right: 5vw; */
    color: white;
    z-index: 999;
    transform: translateX(-9px);
    transition: top 5s;
    -webkit-transition: top 5s;
}
.foward-action-removed{
    /* position: absolute;
    top: 80px;
    right: 5vw;
    color: white;
    z-index: 0; */
    display: none !important;
}

.museum{
    /* border: solid 2px blue; */
    height: 26.5vh; 
    width: 56px; 
    position: absolute; 
    top: .3vw;
    right: 5vw;
    z-index: 999;
}

.fab-buttons{
    margin-top: 1vh;
}
.fab-buttons :hover{
    background-color:  #4db6ac;
}

.profile-picture-frame {
    color: white;
    font-size: 2em;
    font-weight: 200;
    width:100px; 
    height:100px;
    float: left;
    /* margin-top: 10px; */
    /* margin-right: 10px; */
    display: flex;
    justify-content: center;
    align-items:center;
    border-radius: 50px;
    background-color: teal;
    border: 4px solid teal;
}
.profile-picture-frame p{
    font-size: 1.7em;
}
.profile-picture-frame:hover {
    /* background-color:  #4db6ac; */
    /* border: 2px solid #4db6ac; */
    /* transform: scale(1.01); */
}

.profile-picture-frame img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
}

#profile{
    width: 90%;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    -webkit-overflow-scrolling: touch;
}
.profile-top-row{
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    justify-content: space-between;
    align-items: center;
}
.fileupload-wrapper{
    /* padding-top: 50px !important; */
    width: 100px !important;
    /* display: none; */
}
.profile-modal{
    margin-top: 20px;
}
.profile-modal #firstName,.profile-modal  #lastName{
    font-size: 2rem;
    padding-top: 20px;
}
#email{
    margin-top:0;
}
#phototip{

}
.password-fields{
    min-width: 70%;
    /* align-content: space-between; */
}

.modal-content { 
    padding-bottom: none !important;
}

/* .profile-top-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

} */

.profile-top-row h3{
    display: flex;
}

.profile-second-row{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 240px;
}

.profile-second-row button{
    background-color: teal;
}

.old-password-input {
    visibility: hidden;
}

.new-password-input {
    visibility: hidden;
}

.password-fields {
    width: 60% !important;
}
.change-password-button {
    margin: 20px;
}

.shake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
10%, 90% {
    transform: translate3d(-1px, 0, 0);
}

20%, 80% {
    transform: translate3d(2px, 0, 0);
}

30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
}

40%, 60% {
    transform: translate3d(4px, 0, 0);
}
}

.fileupload-wrapper{
    opacity: 0;
    width : 100px;
    height: 100px; 
    position: absolute;
    border-radius: 50px;
    z-index: 1000;
    /* float: left; */
}
.fileupload-wrapper input{
    border-radius: 50px;
    width : 100%;
    height: 100%; 
    /* float: left; */
}

.fileupload-icon{
    opacity: 0;
    /* width : 100px; */
    /* height: 100px;  */
    position: absolute;
    transform: translateY(35px);
    z-index: 999;
    /* border-radius: 60px; */
    background-color: rgba(255, 255, 255, .4);
    text-align: center;
    /* height: 100; */
    width: 100px;
    /* border-radius: 50px; */
    /* float: left; */
}
.fileupload-icon i{
    font-weight: 900;
    /* opacity: 0; */
    /* width : 100px; */
    /* height: 100px;  */
    /* justify-content: baseline;
    align-items: flex-end;  */
    /* position: absolute; */
    /* border-radius: 50px; */
    /* float: left; */
} 

.triptoggle {
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
}

.profile-tool-tip{
    left: 130px;
    width: 60px;
    visibility: visible;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: relative;
    z-index: 1;
    top: -66px;
    left: -88px;
}

.profile-tool-tip:hover{
    left: -87px;
}

.profile-tool-tip::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #424242 ;
}

.undodelete-tooltip {
    float: right;
    left: 22px;
    width: 100px !important;
    visibility: visible;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: relative;
    z-index: 1;
    top: 5px;
    /* left: -40%; */
}
.undodelete-tooltip:hover{
    left: 25px;
}
.undodelete-tooltip::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent #424242 ;
}

#saving, #savingTrip {
    position: absolute;
    display: none;
    top: 5px;
    left: 15px;
    color: #616161;
}


#loading {
    background-color: rgba(255, 255, 255, 0);
    border: none;
    box-shadow: none;
    margin: 15% 43%;
}

.modal-footer {
    display: none !important;

}

.landingtitle {
    margin-left: 15px;
    margin-bottom: 0;
    /* margin-top: 20px; */
}

.white.signin-signup {
    margin-top: 0;
    background-color: rgba(255, 255, 255, .85) !important;
    border-radius: 2px;
}
.signin-signup{
    font-size: 1rem;
}

.landing-page-div {
    margin-top: 20px;
}

.trip-title {
    margin-bottom: 0;
}
.dragdrop {
height: 150px;
/* border: 1px solid teal !important; */
}


.dragdrop-div {
    border: 1px dashed teal !important;
    height: 100%;
    box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.5);
    

    
}

.dragdrop-div:hover {
    box-shadow: -1px 9px 18px 0px rgba(0,0,0,0.75);
    transform: scale(1.01);
}

.delete-trip-btn {
    top: -45px;
    left: 30px;
}

.helper-text {
    margin-top: 25px;
    z-index: 10;
    cursor: pointer;
}

.helper-text:hover {
    color:#26a69a !important;
}


.carousel-wrapper{
    position: relative;
}

.carousel-close{
    color: rgb(0, 150, 136);
    opacity: .4;
    position: absolute;
    top:0;
    right: 0;
    transform: scale(1);

}
.carousel-close:hover{
    /* color: rgba(255, 255, 255, 0.8);
    text-shadow: rgba(0, 0, 0, 0.4) 1px 1px;
    transform: scale(1.2); */
    /* z-index: 9999999; */
    cursor: pointer;
    opacity: 1;
}
/* .tripphoto-tool-tip{ */
    /* left: 130px; */
    /* width: 100px;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px; */
    /* Position the tooltip text - see examples below! */
    /* position: relative;
    z-index: 1; */
    /* right: -100%; */
    /* right: -20px; */
    /* left: -20px; */
    /* right: 20px; */
    /* left: 80%; */
    /* top: 2px; */
    /* left: 70; */
/* } */

.addtripphotobtn {
    margin: 10px;

}

#tripdetails{
    width: 90%;
    -webkit-overflow-scrolling: touch;
}
.delete-trip-btn{
    float: right;
    margin-right: 22px;
    top: 0px;
}
.modal-content{
    padding: 0 !important;
    margin: 10px !important;
}
.map-detail-wrapper{
    display: flex;
    flex-direction: column;
}

.map-detail-wrapper p{
    max-width: 100%;
    max-height: 210px;
    overflow-y: auto;
}
.mini-map{
    min-width: 250px;
    min-height: 250px;
}
.mini-map img{
    width: 100%;
    height: 100%;
}



.dashboard{
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 8px;
}

.dashboard-tools{
    display: block;
    max-width: 95vw;
}
.addtrip-searchtrip{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.triptoggle{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.trip-list{
    max-height: 15vh;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 5px;
    background-color: #c0eae6;
    border-radius: 2px;
}

#mapbox{
    width: 95vw;
    height: 67vmin;
}

.mapbox-gl {
    border-radius: 2px;
}


.logo-tagline{
    position: relative;
}

.tagline{
    position: absolute;
    right: 4%;
    top:65%;
    color: #4db6ac;
    font-size: .8em;
}


/* > iPhone 6 */
@media only screen and (min-width: 360px){
    .dashboard{
        margin: 10px;
    }
}
/* > iPhone X */
@media only screen and (min-height: 800px){
    .dashboard{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
    }
    .addtrip-searchtrip{
        margin-top: 0;
        padding: 0;
    }
    .triptoggle{
        margin-top: 10px;
    }
    .trip-list{
        max-height: 200px;
        margin-top: 10px;
    }
    #mapbox{
        margin-top: 25px;
    }
}

/* > iPhone Landscape */
@media only screen and (min-width: 660px){
    .addtrip-searchtrip{
        justify-content: flex-start;
    }
    .dashboard-toggle-list{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
    .triptoggle{
        margin-top: -10px;
        margin-left: 100px;
        flex-direction: column;
        align-self: right;
    }
    .trip-list{
        max-height: 15vh;
        margin-bottom: 10px;
        margin-left: 40px;
        flex-direction: column;
        width: 350px;
    }
    #mapbox{
        height: 365px;
    }
    #profile{
        width: 70%;
    }
    .profile-top-row{
        justify-content: space-evenly;
    }

    #tripdetails{
        width: 70%;
        -webkit-overflow-scrolling: touch;
    }
    .delete-trip-btn{
        float: right;
        margin-right: 22px;
        top: 0px;
    }
    .modal-content{
        padding: 0 !important;
        margin: 10px !important;
    }
    .map-detail-wrapper{
        display: flex;
        flex-direction: row;
        margin-left: 10px !important;
        margin-right: 10px !important;
        justify-content: space-around;
    }
    .map-detail-wrapper p{
        margin: 10px;
        min-width: 420px;
        max-width: 420px;
        max-height: 240px;
        overflow-y: auto;
    }
    .mini-map{
        min-width: 250px;
        min-height: 250px;
        max-width: 330px;
        max-height: 330px;
    }
    .mini-map img{
        width: 100%;
        height: 100%;
    }
    .profile-tool-tip{
        width: 60px;
        visibility: visible;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        /* Position the tooltip text - see examples below! */
        position: relative;
        z-index: 1;
        top: -66px;
        left: -88px;
    }
    .profile-tool-tip:hover{
        left: -95;
    }
    .tagline{
        font-size: 1em;
    }
}
/* > iPad */
@media only screen and (min-width: 760px){
    .dashboard{
        margin-left: 20px;
    }
    .addtrip-searchtrip{
        justify-content: flex-start;
    }
    .dashboard-toggle-list{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
    .triptoggle{
        margin-right: 40px;
        flex-direction: column;
    }
    .trip-list{
        max-height: 15vh;
        margin-bottom: 10px;
        flex-direction: column;
        max-width: 350px;
    }
    #mapbox{
        height: 62vmin;
    }
}

/* > iPad Landscape */
@media only screen and (min-width: 1020px){
    .dashboard{
        display: flex;
        flex-direction: row;
        margin-left: 10px;
    }
    .dashboard-tools{
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        max-width: 20vw;
        min-width: 20vw;
        align-items: center;
    }
    .dashboard-map-n-tools{
        display: flex;
    }
    .triptoggle{
        margin-left: 20px;
    }
    .dashboard-toggle-list{
        flex-direction: column;
    }
    .trip-list{
        margin-left: 00px;
        margin-right: 0px;
        max-height: 61vh;
        max-width: 215px;
    }
    #mapbox{
        width: 77vw;
        max-width: 77vw;
        height: 89vh;
        max-height: 89vh;
    }
    #profile{
        width: 550px;
    }
}

/* Desktop */
@media only screen and (min-width: 1030px){
    .dashboard{
        display: flex;
        flex-direction: row;
        padding: 0;
        margin-left: 10px;
    }
    .dashboard-tools{
        flex-direction: column;
        max-width: 20vw;
        min-width: 20vw;
        padding: 0;
        margin: 0;
        margin-right: 10px;
        justify-content: flex-start;
        max-height: 100vh;
    }
    .trip-list{
        max-height: 61vh;
        max-width: 225px;
    }
    .dashboard-toggle-list{
        flex-direction: column;
    }
    .dashboard-map-n-tools{
        display: flex;
    }
    .trip-list ul{
        text-align: left !important;
    }

    #mapbox{
        width: 77vw;
        max-width: 77vw;
        height: 89vh;
        max-height: 89vh;
    }
    .tooltip {
        width: 120px;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        top: 66px;
        left: 155px;
        
    }
    .tagline{
        font-size: 1.25em;
    }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 30% auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(38,166,154, 0.2);
  border-right: 1.1em solid rgba(38,166,154, 0.2);
  border-bottom: 1.1em solid rgba(38,166,154, 0.2);
  border-left: 1.1em solid #26a69a;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}