html {
  min-height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #e0f2f1;
  min-height: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
form {
  padding: 20px;
  margin-top: 60px;
}